<template>
    <div>
        <a-row :gutter="24" class="body" id="ygh-content-box" type="flex">
            <a-col :md="5" :sm="24" style="padding-left: 0" class="height100">
                <a-tabs default-active-key="1" @change="callback" class="tabs-tree" style="height: 100%">
                    <a-tab-pane key="1" tab="组织架构">
                        <a-card :bordered="false" :loading="treeLoading">
                            <div v-if="this.orgTree != ''">
                                <a-tree :treeData="orgTree" v-if="orgTree.length" :replaceFields="replaceFields">
                                    <template slot="custom" slot-scope="item">
                                        <div class="flex0" @click="handleClick(item)">
                                            <img
                                                src="/images/directory.svg"
                                                alt=""
                                                v-if="item.slots.icon == 'tags'"
                                                class="blue-ic"
                                            />
                                            <img
                                                src="/images/menu.svg"
                                                alt=""
                                                v-if="item.slots.icon == 'tag'"
                                                class="blue-ic"
                                            />
                                            <!-- <a-icon v-if="item.slots.icon == 'tags'" type="folder-open" theme="twoTone" class="blue-ic" />
                    <a-icon v-if="item.slots.icon == 'tag'" type="folder" theme="twoTone" class="blue-ic" /> -->
                                            <OpenData
                                                type="departmentName"
                                                :openid="item.id"
                                                :corpid="item.corpId"
                                            ></OpenData>
                                        </div>
                                    </template>
                                </a-tree>
                            </div>
                            <div v-else>
                                <a-empty :image="simpleImage" />
                            </div>
                        </a-card>
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="标签" force-render>
                        <template v-if="tags.length > 0">
                            <a-card style="border: none">
                                <div class="tagContainer">
                                    <div class="tags">
                                        <ul>
                                            <li
                                                class="linContainer"
                                                :class="{
                                                    active: tagActive === tagIndex,
                                                }"
                                                @click="changeActive(tag, tagIndex)"
                                                v-for="(tag, tagIndex) in tags"
                                                :key="tag.id"
                                            >
                                                <div class="flex0">
                                                    <div>
                                                        <img class="tagIcon" src="/images/tag.svg" alt="" />
                                                    </div>
                                                    <div>
                                                        {{ tag.name }}
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </a-card>
                        </template>
                        <template v-else>
                            <a-empty :image="simpleImage" />
                        </template>
                    </a-tab-pane>
                </a-tabs>
            </a-col>
            <a-col :md="19" :sm="24" style="padding-right: 0" class="height100">
                <div v-if="hasPerm('sysUser:page')" class="user-search-wrapper">
                    <x-card>
                        <div slot="content" class="table-page-search-wrapper">
                            <a-form layout="inline">
                                <a-row :gutter="48">
                                    <a-col :md="8" :sm="24">
                                        <a-form-item label="关键词">
                                            <a-input
                                                v-model="queryParam.searchValue"
                                                allow-clear
                                                placeholder="请输入姓名、账号"
                                            />
                                        </a-form-item>
                                    </a-col>

                                    <a-col :md="12" :sm="24">
                                        <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                                        <a-button style="margin-left: 8px" @click="reset">重置</a-button>
                                        <a-popconfirm
                                            placement="topRight"
                                            title="确定同步吗？"
                                            @confirm="() => syncBook()"
                                        >
                                            <a-button style="margin-left: 8px" type="primary">同步组织架构</a-button>
                                        </a-popconfirm>
                                    </a-col>
                                </a-row>
                            </a-form>
                        </div>
                    </x-card>
                </div>
                <a-card :bordered="false" style="height: calc(100% - 90px)">
                    <s-table ref="table" :columns="columns" :data="loadData" :rowKey="(record) => record.id">
                        <template slot="operator"> </template>

                        <span slot="userId" slot-scope="text, record">
                            <div>
                                <OpenData type="userName" :openid="text" :corpid="record.corpId"></OpenData>
                            </div>
                        </span>
                        <span slot="my_action" slot-scope="text, record">
                            <span>{{ record.userId }}</span>
                        </span>
                        <span slot="departmentName" slot-scope="text, record">
                            <div>
                                <OpenData type="departmentName" :openid="text" :corpid="record.corpId"></OpenData>
                            </div>
                        </span>

                        <!-- <span slot="action" slot-scope="text, record">
                                  <a @click="$refs.userRoleForm.userRole(record)" v-if="hasPerm('sysUser:grantRole')">授权角色</a>
                                </span> -->
                    </s-table>
                    <add-form ref="addForm" @ok="handleOk" />
                    <edit-form ref="editForm" @ok="handleOk" />
                    <user-role-form ref="userRoleForm" @ok="handleOk" />
                    <user-org-form ref="userOrgForm" @ok="handleOk" />
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>
<script>
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'

import { corpDeptPage, syncBookMess, wx_org } from '@/api/modular/fileStream/user'

import { documentLablePage, getLablePersonPage } from '@/api/modular/fileStream/documentLabelManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { sysUserChangeStatus, sysUserDelete, sysUserExport, sysUserResetPwd } from '@/api/modular/system/userManage'
import { STable, XCard, XDown } from '@/components'
import { handleAppointEleHeight } from '@/utils/common.js'
import { Empty } from 'ant-design-vue'
import addForm from './addForm'
import editForm from './editForm'
import userOrgForm from './userOrgForm'
import userRoleForm from './userRoleForm'
export default {
    components: {
        XDown,
        XCard,
        STable,
        addForm,
        editForm,
        userRoleForm,
        userOrgForm,
        OpenData,
    },
    data() {
        return {
            tags: [],
            tagActive: 0,
            tabActive: '1',
            deptIds: {},
            // 查询参数
            queryParam: {},
            // 表头
            columns: [
                {
                    title: '姓名',
                    dataIndex: 'userId',
                    width: '150px',
                    scopedSlots: { customRender: 'userId' },
                },
                {
                    title: '账号',
                    dataIndex: 'my_action',
                    scopedSlots: { customRender: 'my_action' },
                },
                {
                    title: '企业名称',
                    dataIndex: 'corpName',
                },
                {
                    title: '部门名称',
                    dataIndex: 'deptId',
                    scopedSlots: { customRender: 'departmentName' },
                },
            ],
            // 加载数据方法 必须为 Promise 对象
            loadData: (parameter) => {
                if (this.tabActive === '2') {
                    return getLablePersonPage(
                        Object.assign(parameter, { labelId: this.tagActiveRow.id }, this.queryParam)
                    ).then((res) => {
                        handleAppointEleHeight(
                            '#ygh-content-box',
                            ['.user-search-wrapper', '.s-table-tool', '.ant-alert-info'],
                            99,
                            res
                        )
                        return res.data
                    })
                }
                return corpDeptPage(Object.assign(parameter, this.deptIds, this.queryParam)).then((res) => {
                    handleAppointEleHeight(
                        '#ygh-content-box',
                        ['.user-search-wrapper', '.s-table-tool', '.ant-alert-info'],
                        99,
                        res
                    )
                    return res.data
                })
            },
            orgTree: [],
            selectedRowKeys: [],
            selectedRows: [],
            sexDictTypeDropDown: [],
            statusDictTypeDropDown: [],
            treeLoading: true,
            simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
            replaceFields: {},
            options: {
                alert: {
                    show: true,
                    clear: () => {
                        this.selectedRowKeys = []
                    },
                },
                rowSelection: {
                    selectedRowKeys: this.selectedRowKeys,
                    onChange: this.onSelectChange,
                },
            },
        }
    },
    created() {
        this.getTree()
        this.sysDictTypeDropDown()
        this.getTags()
    },
    mounted() {
        // handleAppointEleHeight('#ygh-content-box', ['.user-search-wrapper', '.s-table-tool', '.ant-alert-info'], 99)
    },
    methods: {
        changeActive(tag, tagIndex) {
            this.tagActive = tagIndex
            this.tagActiveRow = tag
            this.$refs.table.refresh()
        },
        syncBook() {
            syncBookMess()
                .then((res) => {
                    if (res.code === 200) {
                        this.$message.success('同步成功')
                        this.getTree()
                    } else {
                        this.$message.info(res.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getTree() {
            /**
             * 获取到机构树，展开顶级下树节点，考虑到后期数据量变大，不建议全部展开
             */
            wx_org(Object.assign(this.queryParam))
                .then((res) => {
                    this.treeLoading = false
                    if (!res.success) {
                        return
                    }
                    //
                    const mydata = Object.assign([], res.data)
                    function make_icon(data) {
                        data.forEach((element) => {
                            element.scopedSlots = { title: 'custom' }
                            element.children && element.children.length == 0
                                ? (element.slots = { icon: 'tag' })
                                : (element.slots = { icon: 'tags' })
                            if (element.children && element.children.length > 0) {
                                make_icon(element.children)
                            } else {
                                return
                            }
                        })
                    }
                    make_icon(mydata)
                    //
                    this.orgTree = mydata
                })
                .then((x) => {
                    init_wx()
                })
        },
        sexFilter(sex) {
            // eslint-disable-next-line eqeqeq
            const values = this.sexDictTypeDropDown.filter((item) => item.code == sex)
            if (values.length > 0) {
                return values[0].value
            }
        },
        statusFilter(status) {
            // eslint-disable-next-line eqeqeq
            const values = this.statusDictTypeDropDown.filter((item) => item.code == status)
            if (values.length > 0) {
                return values[0].value
            }
        },
        /**
         * 获取字典数据
         */
        sysDictTypeDropDown(text) {
            sysDictTypeDropDown({ code: 'sex' }).then((res) => {
                this.sexDictTypeDropDown = res.data
            })
            sysDictTypeDropDown({ code: 'common_status' }).then((res) => {
                this.statusDictTypeDropDown = res.data
            })
        },
        /**
         * 修改用户状态
         */
        editUserStatus(code, record) {
            // eslint-disable-next-line no-unused-vars
            const status = 0
            // eslint-disable-next-line eqeqeq
            if (code == 0) {
                this.status = 1
                // eslint-disable-next-line eqeqeq
            } else if (code == 1) {
                this.status = 0
            }
            sysUserChangeStatus({ id: record.id, status: this.status }).then((res) => {
                if (res.success) {
                    this.$message.success('操作成功')
                    this.$refs.table.refresh()
                } else {
                    this.$message.error('操作失败：' + res.message)
                }
            })
        },
        /**
         * 重置密码
         */
        resetPwd(record) {
            sysUserResetPwd({ id: record.id }).then((res) => {
                if (res.success) {
                    this.$message.success('重置成功')
                    // this.$refs.table.refresh()
                } else {
                    this.$message.error('重置失败：' + res.message)
                }
            })
        },
        reset() {
            this.queryParam = {}
            this.$refs.table.refresh()
        },
        /**
         * 单个删除
         */
        singleDelete(record) {
            const param = [{ id: record.id }]
            this.sysUserDelete(param)
        },
        /**
         * 批量删除
         */
        batchDelete() {
            const paramIds = this.selectedRowKeys.map((d) => {
                return { id: d }
            })
            this.sysUserDelete(paramIds)
        },
        /**
         * 删除用户
         */
        sysUserDelete(param) {
            sysUserDelete(param)
                .then((res) => {
                    if (res.success) {
                        this.$message.success('删除成功')
                        this.$refs.table.refresh()
                    } else {
                        this.$message.error('删除失败：' + res.message)
                    }
                })
                .catch((err) => {
                    this.$message.error('删除错误：' + err.message)
                })
        },
        /**
         * 批量导出
         */
        batchExport() {
            sysUserExport().then((res) => {
                this.$refs.batchExport.downloadfile(res)
            })
        },
        /**
         * 点击左侧机构树查询列表
         */
        handleClick(it) {
            this.deptIds = { deptId: it.id, corpId: it.corpId }
            this.$refs.table.refresh(true)
        },

        handleOk() {
            this.$refs.table.refresh()
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
        },
        callback(key) {
            this.tabActive = key
            this.$refs.table.refresh()
        },
        getTags() {
            documentLablePage().then((res) => {
                if (res.code === 200) {
                    this.tags = res.data.rows
                    this.tagActiveRow = res.data.rows.length > 0 ? res.data.rows[0] : {}
                } else {
                    this.$message.warning(res.message)
                }
            })
        },
    },
}
</script>
<style lang="less" scoped>
@import url('../../../common');

.body {
    width: 1280px;
    margin: auto !important;
}

.blue-ic {
    // font-size: 1.5em;
    width: 21px;
    height: 21px;
    margin-right: 10px;
}

.flex0 {
    display: flex;
    align-items: center;
}

.tabs-tree {
    background: #fff;
}

::v-deep .ant-tabs-bar {
    margin: 0;
}

::v-deep .ant-tabs-nav-scroll {
    display: flex;
    justify-content: space-around;
}

.tagContainer {
    margin-top: 0;
}
</style>
